const l = [
  {
    url: 'ke/ke.svg',
    print: 'ከ',
    letterIndex: 11,
    vindex: 0,
    sound: 'ke/ke.mp3',
  },
  {
    url: 'ke/ku.svg',
    print: 'ኩ',
    letterIndex: 11,
    vindex: 1,
    sound: 'ke/ku.mp3',
  },
  {
    url: 'ke/ki.svg',
    print: 'ኪ',
    letterIndex: 11,
    vindex: 2,
    sound: 'ke/ki.mp3',
  },
  {
    url: 'ke/ka.svg',
    print: 'ካ',
    letterIndex: 11,
    vindex: 3,
    sound: 'ke/ka.mp3',
  },
  {
    url: 'ke/kay.svg',
    print: 'ኬ',
    letterIndex: 11,
    vindex: 4,
    sound: 'ke/kay.mp3',
  },
  {
    url: 'ke/keu.svg',
    print: 'ክ',
    letterIndex: 11,
    vindex: 5,
    sound: 'ke/keu.mp3',
  },

  {
    url: 'ke/ko.svg',
    print: 'ኮ',
    letterIndex: 11,
    vindex: 6,
    sound: 'ke/ko.mp3',
  },
]

export default l
