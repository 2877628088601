const l = [
  {
    url: 'kke/kke.svg',
    print: 'ቀ',
    letterIndex: 12,
    vindex: 0,
    sound: 'kke/kke.mp3',
  },
  {
    url: 'kke/kku.svg',
    print: 'ቁ',
    letterIndex: 12,
    vindex: 1,
    sound: 'kke/kku.mp3',
  },
  {
    url: 'kke/kki.svg',
    print: 'ቂ',
    letterIndex: 12,
    vindex: 2,
    sound: 'kke/kki.mp3',
  },
  {
    url: 'kke/kka.svg',
    print: 'ቃ',
    letterIndex: 12,
    vindex: 3,
    sound: 'kke/kka.mp3',
  },
  {
    url: 'kke/kkay.svg',
    print: 'ቄ',
    letterIndex: 12,
    vindex: 4,
    sound: 'kke/kkay.mp3',
  },
  {
    url: 'kke/kkeu.svg',
    print: 'ቅ',
    letterIndex: 12,
    vindex: 5,
    sound: 'kke/kkeu.mp3',
  },

  {
    url: 'kke/kko.svg',
    print: 'ቆ',
    letterIndex: 12,
    vindex: 6,
    sound: 'kke/kko.mp3',
  },
]

export default l
