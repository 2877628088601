const l = [
  {
    url: 'me/me.svg',
    print: 'መ',
    letterIndex: 14,
    vindex: 0,
    sound: 'me/me.mp3',
  },

  {
    url: 'me/mu.svg',
    print: 'ሙ',
    letterIndex: 14,
    vindex: 1,
    sound: 'me/mu.mp3',
  },

  {
    url: 'me/mi.svg',
    print: 'ሚ',
    letterIndex: 14,
    vindex: 2,
    sound: 'me/mi.mp3',
  },

  {
    url: 'me/ma.svg',
    print: 'ማ',
    letterIndex: 14,
    vindex: 3,
    sound: 'me/ma.mp3',
  },

  {
    url: 'me/may.svg',
    print: 'ሜ',
    letterIndex: 14,
    vindex: 4,
    sound: 'me/may.mp3',
  },

  {
    url: 'me/meu.svg',
    print: 'ም',
    letterIndex: 14,
    vindex: 5,
    sound: 'me/meu.mp3',
  },

  {
    url: 'me/mo.svg',
    print: 'ሞ',
    letterIndex: 14,
    vindex: 6,
    sound: 'me/mo.mp3',
  },
]

export default l
