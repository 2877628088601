const l = [
  {
    url: 'pe/pe.svg',
    print: 'ፐ',
    letterIndex: 16,
    vindex: 0,
    sound: 'pe/pe.mp3',
  },

  {
    url: 'pe/pu.svg',
    print: 'ፑ',
    letterIndex: 16,
    vindex: 1,
    sound: 'pe/pu.mp3',
  },

  {
    url: 'pe/pi.svg',
    print: 'ፒ',
    letterIndex: 16,
    vindex: 2,
    sound: 'pe/pi.mp3',
  },

  {
    url: 'pe/pa.svg',
    print: 'ፓ',
    letterIndex: 16,
    vindex: 3,
    sound: 'pe/pa.mp3',
  },

  {
    url: 'pe/pay.svg',
    print: 'ፔ',
    letterIndex: 16,
    vindex: 4,
    sound: 'pe/pay.mp3',
  },

  {
    url: 'pe/peu.svg',
    print: 'ፕ',
    letterIndex: 16,
    vindex: 5,
    sound: 'pe/peu.mp3',
  },

  {
    url: 'pe/po.svg',
    print: 'ፖ',
    letterIndex: 16,
    vindex: 6,
    sound: 'pe/po.mp3',
  },
]

export default l
