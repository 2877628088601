const l = [
  {
    url: 're/re.svg',
    print: 'ረ',
    letterIndex: 18,
    vindex: 0,
    sound: 're/re.mp3',
  },

  {
    url: 're/ru.svg',
    print: 'ሩ',
    letterIndex: 18,
    vindex: 1,
    sound: 're/ru.mp3',
  },

  {
    url: 're/ri.svg',
    print: 'ሪ',
    letterIndex: 18,
    vindex: 2,
    sound: 're/ri.mp3',
  },

  {
    url: 're/ra.svg',
    print: 'ራ',
    letterIndex: 18,
    vindex: 3,
    sound: 're/ra.mp3',
  },

  {
    url: 're/ray.svg',
    print: 'ሬ',
    letterIndex: 18,
    vindex: 4,
    sound: 're/ray.mp3',
  },

  {
    url: 're/reu.svg',
    print: 'ር',
    letterIndex: 18,
    vindex: 5,
    sound: 're/reu.mp3',
  },

  {
    url: 're/ro.svg',
    print: 'ሮ',
    letterIndex: 18,
    vindex: 6,
    sound: 're/ro.mp3',
  },
]

export default l
