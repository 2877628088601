const l = [
  {
    url: "cche/cche.svg",
    print: "ጨ",
    letterIndex: 3,
    vindex: 0,
    sound: "cche/cche.mp3",
  },

  {
    url: "cche/cchu.svg",
    print: "ጩ",
    letterIndex: 3,
    vindex: 1,
    sound: "cche/cchu.mp3",
  },

  {
    url: "cche/cchi.svg",
    print: "ጪ",
    letterIndex: 3,
    vindex: 2,
    sound: "cche/cchi.mp3",
  },

  {
    url: "cche/ccha.svg",
    print: "ጫ",
    letterIndex: 3,
    vindex: 3,
    sound: "cche/ccha.mp3",
  },

  {
    url: "cche/cchay.svg",
    print: "ጬ",
    letterIndex: 3,
    vindex: 4,
    sound: "cche/cchay.mp3",
  },

  {
    url: "cche/ccheu.svg",
    print: "ጭ",
    letterIndex: 3,
    vindex: 5,
    sound: "cche/ccheu.mp3",
  },

  {
    url: "cche/ccho.svg",
    print: "ጮ",
    letterIndex: 3,
    vindex: 6,
    sound: "cche/ccho.mp3",
  },
];

export default l;
