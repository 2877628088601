const l = [
  {
    url: 'she/she.svg',
    asin: '',
    print: 'ሸ',
    letterIndex: 20,
    vindex: 0,
    sound: 'she/she.mp3',
  },

  {
    url: 'she/shu.svg',
    asin: '',
    print: 'ሹ',
    letterIndex: 20,
    vindex: 1,
    sound: 'she/shu.mp3',
  },

  {
    url: 'she/shi.svg',
    asin: '',
    print: 'ሺ',
    letterIndex: 20,
    vindex: 2,
    sound: 'she/shi.mp3',
  },

  {
    url: 'she/sha.svg',
    asin: '',
    print: 'ሻ',
    letterIndex: 20,
    vindex: 3,
    sound: 'she/sha.mp3',
  },

  {
    url: 'she/shay.svg',
    asin: '',
    print: 'ሼ',
    letterIndex: 20,
    vindex: 4,
    sound: 'she/shay.mp3',
  },

  {
    url: 'she/sheu.svg',
    asin: '',
    print: 'ሽ',
    letterIndex: 20,
    vindex: 5,
    sound: 'she/sheu.mp3',
  },

  {
    url: 'she/sho.svg',
    asin: '',
    print: 'ሾ',
    letterIndex: 20,
    vindex: 6,
    sound: 'she/sho.mp3',
  },
]

export default l
