import * as React from 'react'
import { getStroke } from 'perfect-freehand'

const options = {
  size: 16,
  thinning: 0.5,
  smoothing: 0.5,
  streamline: 0.5,
  easing: (t: any) => t,
  start: {
    taper: 0,
    easing: (t: any) => t,
    cap: true,
  },
  end: {
    taper: 100,
    easing: (t: any) => t,
    cap: true,
  },
}

export default function Example({
  clearDrawings,
  onDraw,
}: {
  clearDrawings: boolean
  onDraw: Function
}) {
  const [points, setPoints] = React.useState<any>([])
  const [paths, setPaths] = React.useState<any>([])

  function handlePointerDown(e: any) {
    e.target.setPointerCapture(e.pointerId)
    onDraw(points)
    setPoints([[e.pageX, e.pageY, e.pressure]])
    setPaths([...paths, points])
  }

  function handlePointerMove(e: any) {
    if (e.buttons !== 1) return
    setPoints([...points, [e.pageX, e.pageY, e.pressure]])
    setPaths([...paths, points])
  }

  React.useEffect(() => {
    if (clearDrawings) {
      setPoints([])
      setPaths([])
    }
  }, [clearDrawings])

  return (
    <svg
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      style={{ touchAction: 'none' }}
      className="fixed top-0 bottom-0 right-0 left-0 bg-transparent w-screen h-screen"
    >
      {paths.map((p: any) => {
        const stroke = getStroke(p, options)
        const pathData = getSvgPathFromStroke(stroke)

        return <path key={p} fill="#00ffdd" d={pathData} />
      })}
    </svg>
  )
}

export function getSvgPathFromStroke(stroke: any) {
  if (!stroke.length) return ''

  const d = stroke.reduce(
    (acc: any, [x0, y0]: any, i: any, arr: any) => {
      const [x1, y1] = arr[(i + 1) % arr.length]
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2)
      return acc
    },
    ['M', ...stroke[0], 'Q'],
  )

  d.push('Z')
  return d.join(' ')
}
