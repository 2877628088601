const l = [
  {
    url: "gne/gne.svg",
    print: "ኘ",
    letterIndex: 8,
    vindex: 0,
    sound: "gne/gne.mp3",
  },
  {
    url: "gne/gnu.svg",
    print: "ኙ",
    letterIndex: 8,
    vindex: 1,
    sound: "gne/gnu.mp3",
  },
  {
    url: "gne/gni.svg",
    print: "ኚ",
    letterIndex: 8,
    vindex: 2,
    sound: "gne/gni.mp3",
  },
  {
    url: "gne/gna.svg",
    print: "ኛ",
    letterIndex: 8,
    vindex: 3,
    sound: "gne/gna.mp3",
  },
  {
    url: "gne/gnay.svg",
    print: "ኜ",
    letterIndex: 8,
    vindex: 4,
    sound: "gne/gnay.mp3",
  },
  {
    url: "gne/gneu.svg",
    print: "ኝ",
    letterIndex: 8,
    vindex: 5,
    sound: "gne/gneu.mp3",
  },

  {
    url: "gne/gno.svg",
    print: "ኞ",
    letterIndex: 8,
    vindex: 6,
    sound: "gne/gno.mp3",
  },
];

export default l;
