const l = [
  {
    url: "be/be.svg",
    print: "በ",
    letterIndex: 2,
    vindex: 0,
    sound: "be/be.mp3",
  },

  {
    url: "be/bu.svg",
    print: "ቡ",
    letterIndex: 2,
    vindex: 1,
    sound: "be/bu.mp3",
  },

  {
    url: "be/bi.svg",
    print: "ቢ",
    letterIndex: 2,
    vindex: 2,
    sound: "be/bi.mp3",
  },

  {
    url: "be/ba.svg",
    print: "ባ",
    letterIndex: 2,
    vindex: 3,
    sound: "be/ba.mp3",
  },

  {
    url: "be/bay.svg",
    print: "ቤ",
    letterIndex: 2,
    vindex: 4,
    sound: "be/bay.mp3",
  },

  {
    url: "be/beu.svg",
    print: "ብ",
    letterIndex: 2,
    vindex: 5,
    sound: "be/beu.mp3",
  },

  {
    url: "be/bo.svg",
    print: "ቦ",
    letterIndex: 2,
    vindex: 6,
    sound: "be/bo.mp3",
  },
];

export default l;
