const l = [
  {
    url: "ge/ge.svg",
    print: "ገ",
    letterIndex: 7,
    vindex: 0,
    sound: "ge/ge.mp3",
  },
  {
    url: "ge/gu.svg",
    print: "ጉ",
    letterIndex: 7,
    vindex: 1,
    sound: "ge/gu.mp3",
  },
  {
    url: "ge/gi.svg",
    print: "ጊ",
    letterIndex: 7,
    vindex: 2,
    sound: "ge/gi.mp3",
  },
  {
    url: "ge/ga.svg",
    print: "ጋ",
    letterIndex: 7,
    vindex: 3,
    sound: "ge/ga.mp3",
  },
  {
    url: "ge/gay.svg",
    print: "ጌ",
    letterIndex: 7,
    vindex: 4,
    sound: "ge/gay.mp3",
  },
  {
    url: "ge/geu.svg",
    print: "ግ",
    letterIndex: 7,
    vindex: 5,
    sound: "ge/geu.mp3",
  },

  {
    url: "ge/go.svg",
    print: "ጎ",
    letterIndex: 7,
    vindex: 6,
    sound: "ge/go.mp3",
  },
];

export default l;
