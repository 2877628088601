const l = [
  {
    url: 'le/le.svg',
    print: 'ለ',
    letterIndex: 13,
    vindex: 0,
    sound: 'le/le.mp3',
  },

  {
    url: 'le/lu.svg',
    print: 'ሉ',
    letterIndex: 13,
    vindex: 1,
    sound: 'le/lu.mp3',
  },

  {
    url: 'le/li.svg',
    print: 'ሊ',
    letterIndex: 13,
    vindex: 2,
    sound: 'le/li.mp3',
  },

  {
    url: 'le/la.svg',
    print: 'ላ',
    letterIndex: 13,
    vindex: 3,
    sound: 'le/la.mp3',
  },

  {
    url: 'le/lay.svg',
    print: 'ሌ',
    letterIndex: 13,
    vindex: 4,
    sound: 'le/lay.mp3',
  },

  {
    url: 'le/leu.svg',
    print: 'ል',
    letterIndex: 13,
    vindex: 5,
    sound: 'le/leu.mp3',
  },

  {
    url: 'le/lo.svg',
    print: 'ሎ',
    letterIndex: 13,
    vindex: 6,
    sound: 'le/lo.mp3',
  },
]

export default l
