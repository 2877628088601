const l = [
  {
    url: 'ppe/ppe.svg',
    print: 'ጰ',
    letterIndex: 17,
    vindex: 0,
    sound: 'ppe/ppe.mp3',
  },

  {
    url: 'ppe/ppu.svg',
    print: 'ጱ',
    letterIndex: 17,
    vindex: 1,
    sound: 'ppe/ppu.mp3',
  },

  {
    url: 'ppe/ppi.svg',
    print: 'ጲ',
    letterIndex: 17,
    vindex: 2,
    sound: 'ppe/ppi.mp3',
  },

  {
    url: 'ppe/ppa.svg',
    print: 'ጳ',
    letterIndex: 17,
    vindex: 3,
    sound: 'ppe/ppa.mp3',
  },

  {
    url: 'ppe/ppay.svg',
    print: 'ጴ',
    letterIndex: 17,
    vindex: 4,
    sound: 'ppe/ppay.mp3',
  },

  {
    url: 'ppe/ppeu.svg',
    print: 'ጵ',
    letterIndex: 17,
    vindex: 5,
    sound: 'ppe/ppeu.mp3',
  },

  {
    url: 'ppe/ppo.svg',
    print: 'ጶ',
    letterIndex: 17,
    vindex: 6,
    sound: 'ppe/ppo.mp3',
  },
]

export default l
