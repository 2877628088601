const l = [
  {
    url: "de/de.svg",
    print: "ደ",
    letterIndex: 5,
    vindex: 0,
    sound: "de/de.mp3",
  },
  {
    url: "de/du.svg",
    print: "ዱ",
    letterIndex: 5,
    vindex: 1,
    sound: "de/du.mp3",
  },
  {
    url: "de/di.svg",
    print: "ዲ",
    letterIndex: 5,
    vindex: 2,
    sound: "de/di.mp3",
  },
  {
    url: "de/da.svg",
    print: "ዳ",
    letterIndex: 5,
    vindex: 3,
    sound: "de/da.mp3",
  },
  {
    url: "de/day.svg",
    print: "ዴ",
    letterIndex: 5,
    vindex: 4,
    sound: "de/day.mp3",
  },
  {
    url: "de/deu.svg",
    print: "ድ",
    letterIndex: 5,
    vindex: 5,
    sound: "de/deu.mp3",
  },

  {
    url: "de/do.svg",
    print: "ዶ",
    letterIndex: 5,
    vindex: 6,
    sound: "de/do.mp3",
  },
];

export default l;
