import Draw from './components/Draw'
import React, { useEffect, useState } from 'react'
import {
  RotateCcw,
  ArrowRight,
  ArrowLeft,
  RefreshCcw,
  ArrowRightCircle,
  ArrowLeftCircle,
  Trash2,
  PlayCircle,
  List,
} from 'react-feather'
import letters from './assets/letters/letters'
import LettersListModal, { ModalStatus } from './components/LettersListModal'

import { Howl } from 'howler'

const DEFAULT_LETTER_SIZE = 200
const letterCategoriesLength = letters.length / 7

export default function App() {
  const [letterSize, setLetterSize] = React.useState(DEFAULT_LETTER_SIZE)
  const [clearDrawings, setClearDrawings] = React.useState(false)
  const [letterURL, setLetterURL] = React.useState('')
  const [letterIndex, setLetterIndex] = React.useState(0)
  const [vindex, setVindex] = React.useState(0)
  const [clearDrawingsButtonVisisble, setClearDrawingsButtonVisisble] =
    React.useState(false)
  const [lettersListModalStatus, setLettersListModalStatus] = React.useState(
    ModalStatus.CLOSED,
  )

  const [sound, setSound] = useState('')

  function getLetter(index: number, v: number) {
    return letters.find(
      (letter) => letter.letterIndex === index && letter.vindex === v,
    )
  }

  useEffect(() => {
    async function loadImage() {
      console.log(letterIndex)
      const letter = getLetter(letterIndex, vindex)

      import(`./assets/letters/${letter?.url}`).then((img) => {
        setLetterURL(img.default)
      })

      import(`./assets/letters/${letter?.sound}`).then((s) => {
        setSound(s.default)
      })
    }

    loadImage()
  }, [letterIndex, vindex])

  function handleLetterSizeInputChange(e: any) {
    const value = e.target.value
    if (value > 250) {
      return
    }

    setLetterSize(e.target.value)
  }

  function handleResetLetterSize() {
    setLetterSize(DEFAULT_LETTER_SIZE)
  }

  function handleOnDraw(points: any) {
    setClearDrawings(false)
    setClearDrawingsButtonVisisble(true)
  }

  function handleClearDrawings() {
    setClearDrawingsButtonVisisble(false)
    setClearDrawings(true)
  }

  function handleNextVindex() {
    setVindex((v) => v + 1)
    handleClearDrawings()
  }

  function handlePrevVindex() {
    setVindex((v) => v - 1)
    handleClearDrawings()
  }

  function handlePrevLetterIndex() {
    if (letterIndex === 0) {
      return
    }

    setLetterIndex((l) => l - 1)
    handleClearDrawings()
  }

  function handleNextLetterIndex() {
    if (letterIndex === letterCategoriesLength) {
      return
    }

    setLetterIndex((l) => l + 1)
    handleClearDrawings()
  }

  function handleRandomize() {
    setLetterIndex(Math.floor(Math.random() * letterCategoriesLength))
    setVindex(Math.floor(Math.random() * 7))
    handleClearDrawings()
  }

  function handleLettersListModalOpen() {
    setLettersListModalStatus(ModalStatus.OPEN)
  }

  function handleLettersListRequestClose() {
    setLettersListModalStatus(ModalStatus.CLOSED)
  }

  function handleLetterClick(letter: any) {
    setLetterIndex(letter.letterIndex)
    setVindex(letter.vindex)
  }

  function handlePlaySound(letter: any) {
    var s = new Howl({
      src: [sound],
    })

    s.play()
  }

  return (
    <>
      <Draw clearDrawings={clearDrawings} onDraw={handleOnDraw} />

      <div className="fixed top-0 bottom-0 right-0 p-2 flex items-center justify-center">
        {clearDrawingsButtonVisisble && (
          <button
            className="ml-2 p-2 rounded bg-white"
            onClick={handleClearDrawings}
          >
            <span>
              <Trash2 className="w-6 h-6 relative" />
            </span>
          </button>
        )}
      </div>

      <div className="fixed top-0 left-0 right-0  h-12 flex items-center justify-center p-4">
        <input
          type="number"
          className="rounded-l-md p-2 w-20 text-center"
          value={letterSize}
          onChange={handleLetterSizeInputChange}
        />

        <button
          className="p-2 pr-3 rounded-r bg-white"
          onClick={handleResetLetterSize}
        >
          <span>
            <RotateCcw className="w-6 h-6 relative" />
          </span>
        </button>
      </div>

      <div className="fixed top-0 right-0 p-2 flex items-center justify-center">
        <button
          className="ml-2 p-2 rounded bg-white"
          onClick={handleLettersListModalOpen}
        >
          <span>
            <List className="w-6 h-6 relative" />
          </span>
        </button>
      </div>

      <div className="fixed bottom-0 left-0 right-0  h-12 flex items-center justify-center">
        <div className=" p-2 mb-4 rounded-md">
          <button
            className="p-2 rounded bg-white  disabled:opacity-10"
            onClick={handlePrevLetterIndex}
            disabled={letterIndex === 0}
          >
            <span>
              <ArrowLeftCircle className="w-6 h-6 relative" />
            </span>
          </button>

          <button
            className="ml-2 p-2 rounded bg-white disabled:opacity-10"
            onClick={handleNextLetterIndex}
            disabled={letterIndex === letters.length / 7 - 1}
          >
            <span>
              <ArrowRightCircle className="w-6 h-6 relative" />
            </span>
          </button>

          <button
            className="ml-6 mr-6 p-2 rounded bg-white disabled:opacity-10"
            onClick={handleRandomize}
          >
            <span>
              <RefreshCcw className="w-6 h-6 relative" />
            </span>
          </button>

          <button
            className="p-2 rounded bg-white disabled:opacity-10"
            disabled={vindex === 0}
            onClick={handlePrevVindex}
          >
            <span>
              <ArrowLeft className="w-6 h-6 relative" />
            </span>
          </button>

          <button
            className="ml-2 p-2 rounded bg-white disabled:opacity-10"
            disabled={vindex === 6}
            onClick={handleNextVindex}
          >
            <span>
              <ArrowRight className="w-6 h-6 relative" />
            </span>
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center min-w-max min-h-screen">
        <img
          src={letterURL}
          style={{ width: `${letterSize}px`, height: `${letterSize}px` }}
          alt=""
        />

        <div className="mt-16 flex flex-col items-center space-around">
          <p className="text-4xl">{getLetter(letterIndex, vindex)?.print} </p>
          <button className="p-2 mt-2 rounded">
            <span>
              <PlayCircle
                className="w-6 h-6 relative"
                onClick={() => handlePlaySound(getLetter(letterIndex, vindex))}
              />
            </span>
          </button>
        </div>
      </div>

      <div className="fixed top-0 right-0 p-2 flex items-center justify-center">
        <button
          className="ml-2 p-2 rounded bg-white"
          onClick={handleLettersListModalOpen}
        >
          <span>
            <List className="w-6 h-6 relative" />
          </span>
        </button>
      </div>

      <LettersListModal
        status={lettersListModalStatus}
        onRequestClose={handleLettersListRequestClose}
        letters={letters}
        onLetterClick={(letter) => handleLetterClick(letter)}
      />
    </>
  )
}
