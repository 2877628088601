const l = [
  {
    url: "aa2/aa.svg",
    print: "ዐ",
    letterIndex: 1,
    vindex: 0,
    sound: "aa/aa.mp3",
  },

  {
    url: "aa2/aau.svg",
    print: "ዑ",
    letterIndex: 1,
    vindex: 1,
    sound: "aa/aau.mp3",
  },

  {
    url: "aa2/aai.svg",
    print: "ዒ",
    letterIndex: 1,
    vindex: 2,
    sound: "aa/aai.mp3",
  },

  {
    url: "aa2/aaa.svg",
    print: "ዓ",
    letterIndex: 1,
    vindex: 3,
    sound: "aa/aa.mp3",
  },

  {
    url: "aa2/aay.svg",
    print: "ዔ",
    letterIndex: 1,
    vindex: 4,
    sound: "aa/aay.mp3",
  },

  {
    url: "aa2/aeu.svg",
    print: "ዕ",
    letterIndex: 1,
    vindex: 5,
    sound: "aa/aeu.mp3",
  },

  {
    url: "aa2/aao.svg",
    print: "ዖ",
    letterIndex: 1,
    vindex: 6,
    sound: "aa/aao.mp3",
  },
];

export default l;
