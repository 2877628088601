import ReactModal from 'react-modal'
import { X } from 'react-feather'

export enum ModalStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

interface props {
  status: ModalStatus
  onRequestClose: () => void
  letters?: Object[]
  onLetterClick?: (letter: any) => void
}

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    opacity: '0.9',
    padding: 0,
    // overflow: 'hidden',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // textAlign: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#f0f0f0',
  },
}

ReactModal.setAppElement('#root')

export default function LettersListModal({
  status,
  onRequestClose,
  letters,
  onLetterClick,
}: props) {
  function handleLetterClick(letter: any) {
    if (!onLetterClick) {
      return
    }

    onLetterClick(letter)
    onRequestClose()
  }

  return (
    <ReactModal
      isOpen={status === ModalStatus.OPEN}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
        <div className="fixed top-0 left-0 right-0 p-4 flex items-center">
          <button onClick={onRequestClose}>
            <span>
              <X className="w-6 h-6 relative" />
            </span>
          </button>
        </div>

        <div className="pl-8 pr-8">
          {letters
            ?.filter((letter: any) => letter.vindex === 0)
            .map((letter: any, index) => {
              return (
                <button
                  key={index}
                  className="bg-white p-2 m-2 roundex-md text-6xl"
                  onClick={() => handleLetterClick(letter)}
                >
                  <p> {letter.print} </p>
                </button>
              )
            })}
        </div>
      </div>
    </ReactModal>
  )
}
