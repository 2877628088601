const l = [
  {
    url: 'ha/ha.svg',
    print: 'ሀ',
    letterIndex: 9,
    vindex: 0,
    sound: 'ha/ha.mp3',
  },
  {
    url: 'ha/hu.svg',
    print: 'ሁ',
    letterIndex: 9,
    vindex: 1,
    sound: 'ha/hu.mp3',
  },
  {
    url: 'ha/hi.svg',
    print: 'ሂ',
    letterIndex: 9,
    vindex: 2,
    sound: 'ha/hi.mp3',
  },
  {
    url: 'ha/haa.svg',
    print: 'ሃ',
    letterIndex: 9,
    vindex: 3,
    sound: 'ha/ha.mp3',
  },
  {
    url: 'ha/hay.svg',
    print: 'ሄ',
    letterIndex: 9,
    vindex: 4,
    sound: 'ha/hay.mp3',
  },
  {
    url: 'ha/heu.svg',
    print: 'ህ',
    letterIndex: 9,
    vindex: 5,
    sound: 'ha/heu.mp3',
  },

  {
    url: 'ha/ho.svg',
    print: 'ሆ',
    letterIndex: 9,
    vindex: 6,
    sound: 'ha/ho.mp3',
  },
]

export default l
