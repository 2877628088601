const l = [
  {
    url: "fe/fe.svg",
    print: "ፈ",
    letterIndex: 6,
    vindex: 0,
    sound: "fe/fe.mp3",
  },
  {
    url: "fe/fu.svg",
    print: "ፉ",
    letterIndex: 6,
    vindex: 1,
    sound: "fe/fu.mp3",
  },
  {
    url: "fe/fi.svg",
    print: "ፊ",
    letterIndex: 6,
    vindex: 2,
    sound: "fe/fi.mp3",
  },
  {
    url: "fe/fa.svg",
    print: "ፋ",
    letterIndex: 6,
    vindex: 3,
    sound: "fe/fa.mp3",
  },
  {
    url: "fe/fay.svg",
    print: "ፌ",
    letterIndex: 6,
    vindex: 4,
    sound: "fe/fay.mp3",
  },
  {
    url: "fe/feu.svg",
    print: "ፍ",
    letterIndex: 6,
    vindex: 5,
    sound: "fe/feu.mp3",
  },

  {
    url: "fe/fo.svg",
    print: "ፎ",
    letterIndex: 6,
    vindex: 6,
    sound: "fe/fo.mp3",
  },
];

export default l;
