const l = [
  {
    url: 'ne/ne.svg',
    print: 'ነ',
    letterIndex: 15,
    vindex: 0,
    sound: 'ne/ne.mp3',
  },

  {
    url: 'ne/nu.svg',
    print: 'ኑ',
    letterIndex: 15,
    vindex: 1,
    sound: 'ne/nu.mp3',
  },

  {
    url: 'ne/ni.svg',
    print: 'ኒ',
    letterIndex: 15,
    vindex: 2,
    sound: 'ne/ni.mp3',
  },

  {
    url: 'ne/na.svg',
    print: 'ና',
    letterIndex: 15,
    vindex: 3,
    sound: 'ne/na.mp3',
  },

  {
    url: 'ne/nay.svg',
    print: 'ኔ',
    letterIndex: 15,
    vindex: 4,
    sound: 'ne/nay.mp3',
  },

  {
    url: 'ne/neu.svg',
    print: 'ን',
    letterIndex: 15,
    vindex: 5,
    sound: 'ne/neu.mp3',
  },

  {
    url: 'ne/no.svg',
    print: 'ኖ',
    letterIndex: 15,
    vindex: 6,
    sound: 'ne/no.mp3',
  },
]

export default l
