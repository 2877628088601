const l = [
  {
    url: 'se/se.svg',
    print: 'ሰ',
    letterIndex: 19,
    vindex: 0,
    sound: 'se/se.mp3',
  },

  {
    url: 'se/su.svg',
    print: 'ሱ',
    letterIndex: 19,
    vindex: 1,
    sound: 'se/su.mp3',
  },

  {
    url: 'se/si.svg',
    print: 'ሲ',
    letterIndex: 19,
    vindex: 2,
    sound: 'se/si.mp3',
  },

  {
    url: 'se/sa.svg',
    print: 'ሳ',
    letterIndex: 19,
    vindex: 3,
    sound: 'se/sa.mp3',
  },

  {
    url: 'se/say.svg',
    print: 'ሴ',
    letterIndex: 19,
    vindex: 4,
    sound: 'se/say.mp3',
  },

  {
    url: 'se/seu.svg',
    print: 'ስ',
    letterIndex: 19,
    vindex: 5,
    sound: 'se/seu.mp3',
  },

  {
    url: 'se/so.svg',
    print: 'ሶ',
    letterIndex: 19,
    vindex: 6,
    sound: 'se/so.mp3',
  },
]

export default l
