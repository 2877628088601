const l = [
  {
    url: 'ha2/ha.svg',
    print: 'ሐ',
    letterIndex: 10,
    vindex: 0,
    sound: 'ha/ha.mp3',
  },
  {
    url: 'ha2/hu.svg',
    print: 'ሑ',
    letterIndex: 10,
    vindex: 1,
    sound: 'ha/hu.mp3',
  },
  {
    url: 'ha2/hi.svg',
    print: 'ሒ',
    letterIndex: 10,
    vindex: 2,
    sound: 'ha/hi.mp3',
  },
  {
    url: 'ha2/haa.svg',
    print: 'ሓ',
    letterIndex: 10,
    vindex: 3,
    sound: 'ha/ha.mp3',
  },
  {
    url: 'ha2/hay.svg',
    print: 'ሔ',
    letterIndex: 10,
    vindex: 4,
    sound: 'ha/hay.mp3',
  },
  {
    url: 'ha2/heu.svg',
    print: 'ሕ',
    letterIndex: 10,
    vindex: 5,
    sound: 'ha/heu.mp3',
  },

  {
    url: 'ha2/ho.svg',
    print: 'ሖ',
    letterIndex: 10,
    vindex: 6,
    sound: 'ha/ho.mp3',
  },
]

export default l
