const l = [
  {
    url: "che/che.svg",
    print: "ቸ",
    letterIndex: 4,
    vindex: 0,
    sound: "che/che.mp3",
  },
  {
    url: "che/chu.svg",
    print: "ቹ",
    letterIndex: 4,
    vindex: 1,
    sound: "che/chu.mp3",
  },
  {
    url: "che/chi.svg",
    print: "ቺ",
    letterIndex: 4,
    vindex: 2,
    sound: "che/chi.mp3",
  },
  {
    url: "che/cha.svg",
    print: "ቻ",
    letterIndex: 4,
    vindex: 3,
    sound: "che/cha.mp3",
  },
  {
    url: "che/chay.svg",
    print: "ቼ",
    letterIndex: 4,
    vindex: 4,
    sound: "che/chay.mp3",
  },
  {
    url: "che/cheu.svg",
    print: "ች",
    letterIndex: 4,
    vindex: 5,
    sound: "che/cheu.mp3",
  },

  {
    url: "che/cho.svg",
    print: "ቾ",
    letterIndex: 4,
    vindex: 6,
    sound: "che/cho.mp3",
  },
];

export default l;
