import aa from './aa/aa'
import aa2 from './aa2/aa2'
import be from './be/be'
import cche from './cche/cche'
import che from './che/che'
import de from './de/de'
import fe from './fe/fe'
import ge from './ge/ge'
import gne from './gne/gne'
import ha from './ha/ha'
import ha2 from './ha2/ha2'
import ke from './ke/ke'
import kke from './kke/kke'
import le from './le/le'
import me from './me/me'
import ne from './ne/ne'
import pe from './pe/pe'
import ppo from './ppe/ppe'
import re from './re/re'
import se from './se/se'
import she from './she/she'

const letters = [
  ...aa,
  ...aa2,
  ...be,
  ...cche,
  ...che,
  ...de,
  ...fe,
  ...ge,
  ...gne,
  ...ha,
  ...ha2,
  ...ke,
  ...kke,
  ...le,
  ...me,
  ...ne,
  ...pe,
  ...ppo,
  ...re,
  ...se,
  ...she,
]

export default letters
